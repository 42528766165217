@keyframes custom-pulse {
    0%, 100% {
      background-color: #191919; /* Your initial color */
    }
    50% {
      background-color: #404040; /* Your new color */
    }
  }
  
  /* Apply the custom animation class to your elements */
  .custom-pulse {
    animation: custom-pulse 1.2s infinite;
  }
  
  @keyframes custom-pulses {
    50%  {
      background-color: #191919; /* Your initial color */
    }
   0%,100% {
      background-color: #404040; /* Your new color */
    }
  }
  
  .custom-pulses {
    animation: custom-pulses 1.2s infinite;
  }
  
  
  @keyframes custom-users {
    50%  {
      background-color: #FAFAFA; /* Your initial color */
    }
   0%,100% {
      background-color: #404040; /* Your new color */
    }
  }
  
  
  
  .custom-users {
    animation: custom-users 1.2s infinite;
  }