.my-input::placeholder {
  color: #FFFFFF4D;
  font-family: 'Montserrat' !important;
}

.my-input.clicked::placeholder {
  color: transparent;
  /* Hide the placeholder text when input is clicked */
}

.my-input-dark-mode::placeholder {
  color: #0000004d;
  font-family: 'Montserrat' !important;
}

.my-input-dark-mode.clicked::placeholder {
  color: transparent;
  /* Hide the placeholder text when input is clicked */
}
