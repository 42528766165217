/* src/styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

body {


  background: black;

  /* margin-top: 20vh; */
  /* font-family: montserrat, sans-serif !important; */

}


/* *{
font-size: 10px;
} */
/* .bg{
  background-image: url('../public//images/bg.jpg')!important;
} */
.wallet_icon {
  margin-top: 25px;
  height: 150px;
  width: auto;
}

.drop {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.remove-zoom {
  zoom: 1.4;
}

.is_not_ff {
  zoom: .7
}

.is_ff {
  transform: scale(0.7, 0.7);
  -ms-zoom: 0.7;
  -webkit-zoom: 0.7;
  -moz-transform: scale(0.7, 0.7);
}

.social_iframe{
  transform: scale(1.4, 1.4);
  -ms-zoom: 1.4;
  -webkit-zoom: 1.4;
  -moz-transform: scale(1.4, 1.4);
}

.social_iframe_{
  zoom:1.4
}