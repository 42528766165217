.error-message-container {
    position: relative;
    width: 100%;
  }
  
  .error-message-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed for the blur effect */
    filter: blur(50px); /* Adjust the blur intensity as needed */
  }
  
  .error-message-content {
    position: relative;
    z-index: 1;
    transition: all 0.3s ease;
  }
  
  .error-message-container.expanded .error-message-overlay {
    display: block;
  }
  